import React from 'react'

const Blog = () => {
  return (
    <>
     <div className='container'>
        <h2 className='text-center'>Blog Section</h2>
     </div>
    </>
  )
}

export default Blog
